import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import "./Header.css";
import SideMenu from "./SideMenu";
import Backdrop from "./Backdrop";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../redux/features/userSlice";
import axios from "axios";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Tippy from "@tippyjs/react";
import LogoutTippy from "./LogoutTippy";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import SearchIcon from "@mui/icons-material/Search";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import PersonIcon from "@mui/icons-material/Person";
import MenuIcon from "@mui/icons-material/Menu";
import SearchContainer from "../SearchContainer";
import CartSideMenu from "../CartSideMenu";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import IMAGES from "../../img/image";
import CryptoJS from "crypto-js";
import getUserData from "../../utils/userDataService.js";
import TollIcon from "@mui/icons-material/Toll";

const Header = () => {
  const { user } = useSelector((state) => state.user);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sideMenu, setSideMenu] = useState(false);
  const [profileMenu, setProfileMenu] = useState(false);
  const [search, setSearch] = useState(false);
  const [cartMenu, setCartMenu] = useState(false);
  const [balance, setBalance] = useState("");

  useEffect(() => {
    getUserData(dispatch, setUser, setBalance);
  }, []);

  return (
    <>
      <header className="header">
        <div className="header-main">
          <div
            className="burger-icon d-block d-lg-none"
            onClick={() => setSideMenu(!sideMenu)}
          >
            <MenuIcon className="icon" />
          </div>
          <SideMenu sideMenu={sideMenu} setSideMenu={setSideMenu} />
          <Backdrop sideMenu={sideMenu} setSideMenu={setSideMenu} />
          <div className="logo" onClick={() => navigate("/")}>
            SHIKIGAMI<span>OFFICIAL</span>
          </div>
          <div className="menus d-none d-md-none d-lg-block">
            <ul className="p-0">
              {/* <li>
                <Link to="/games">Games</Link>
              </li> */}
              <li>
                <Link to="/support">Contact Us</Link>
              </li>
              {!user && (
                <li>
                  <Link to="/login">My Account</Link>
                </li>
              )}
              {user && (
                <li>
                  <Link to="/user-dashboard">Dashboard</Link>
                </li>
              )}
            </ul>
          </div>
          <div className="action-btns">
            {user && (
              <div onClick={() => navigate("/wallet")} className="wallet-cont">
                <span className="me-2">
                  <TollIcon className="icon" />
                </span>
                <span>{parseFloat(balance).toFixed(2)}</span>
              </div>
            )}
            <SearchIcon
              onClick={() => setSearch(!search)}
              className="icon d-none d-lg-block"
            />
            <Tippy
              interactive
              theme="light"
              content={<LogoutTippy user={user && user} />}
            >
              <span className="menu-img-container d-flex">
                <PersonIcon
                  className="icon d-lg-block d-md-none d-none"
                  onClick={() => navigate("/login")}
                />
                {user && (
                  <KeyboardArrowDownIcon
                    className="d-lg-block d-md-none d-none"
                    style={{ color: "#fff" }}
                  />
                )}
              </span>
            </Tippy>
          </div>
        </div>
      </header>
      <CartSideMenu cartMenu={cartMenu} setCartMenu={setCartMenu} />
      <SearchContainer search={search} setSearch={setSearch} />
    </>
  );
};

export default Header;
